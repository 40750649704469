import {
    AWS_SERVER_ENDPOINT,
    AWS_SERVER_ENDPOINT_2,
    AWS_LOCAL_SERVER_ENDPOINT
} from "../constants/enpoints.constants";

export const to = (path) => {
    return `${AWS_SERVER_ENDPOINT}/${path}`;
};

export const to2 = (path) => {
    return `${AWS_SERVER_ENDPOINT_2}/${path}`;
};

export const toLocal = (path) => {
    return `${AWS_LOCAL_SERVER_ENDPOINT}/${path}`;
};
