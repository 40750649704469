import axios from "axios";

import { to,toLocal } from "../../helpers/api.helper";

export const auth = {
    clientSignIn: ({ email, password }) => {
        return axios.post(to("auth/sign-in/client"), {
            username:email, password
        });
    },

    refreshToken: (userID, refreshToken ) => {
        return axios.post(to("auth/refresh"), {
            userID:userID, refreshToken:refreshToken, role:"admin"
        });
    },

    signOut: () => {
        return axios.post(to("auth/sign-out"));
    }
};
