import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { clearErrors, getSDMUUserHistory } from '../../actions/userAction';
import { DELETE_USER_RESET } from '../../constants/userConstants';
import Actions from './Actions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';
import { ConstructionOutlined } from '@mui/icons-material';

const SDMHistoryUserTable = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const userId = params.userid;
    console.log("userId " + userId);

    const { selectedUserSdmInfo } = useSelector((state) => state.selectedUserSdmInfo);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.profile);
    const { sdmHistory } = useSelector((state) => state.sdmHistory);
    useEffect(() => {

        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("User Deleted Successfully", { variant: "success" });

        }
        dispatch(getSDMUUserHistory(userId));
    }, [dispatch, deleteError, isDeleted, enqueueSnackbar]);

    const deleteUserHandler = (id) => {

    }
    var UserSumSDMINFO = null;
    console.log(selectedUserSdmInfo);
    if (selectedUserSdmInfo.sdm) {

        var totalFlow = selectedUserSdmInfo.sdm.find(el => el && el.blockchain === "FLOW");

        var totalFlowDeposit = totalFlow ? totalFlow.values.find(el => el && el.action === "deposit") : null;
        var totalFlowWithdraw = totalFlow ? totalFlow.values.find(el => el && el.action === "withdraw" && el.status === "Success") : null;
        var totalFlowWithdrawPending = totalFlow ? totalFlow.values.find(el => el && el.action === "withdraw" && el.status === "Pending") : null;

        var totalWAX = selectedUserSdmInfo.sdm.find(el => el && el.blockchain === "WAX");

        var totalWAXDeposit = totalWAX ? totalWAX.values.find(el => el && el.action === "deposit") : null;
        var totalWAXWithdraw = totalWAX ? totalWAX.values.find(el => el && el.action === "withdraw" && el.status === "Success") : null;
        var totalWAXWithdrawPending = totalWAX ? totalWAX.values.find(el => el && el.action === "withdraw" && el.status === "Pending") : null;

        UserSumSDMINFO = {
            id: selectedUserSdmInfo.user._id,
            name: (selectedUserSdmInfo.user.username ? selectedUserSdmInfo.user.username : (selectedUserSdmInfo.user.waxName ? selectedUserSdmInfo.user.waxName : (selectedUserSdmInfo.user.flowAccount ? selectedUserSdmInfo.user.flowAccount : "none"))),
            email: (selectedUserSdmInfo.user.email ? (selectedUserSdmInfo.user.email) : "none"),
            totalFlowDeposit: totalFlowDeposit && totalFlowDeposit.totalSDM ? totalFlowDeposit.totalSDM : 0,
            totalWAXDeposit: totalWAXDeposit && totalWAXDeposit.totalSDM ? totalWAXDeposit.totalSDM : 0,

            totalFlowWithdraw: totalFlowWithdraw && totalFlowWithdraw.totalSDM ? totalFlowWithdraw.totalSDM : 0,
            totalFlowWithdrawPending: totalFlowWithdrawPending && totalFlowWithdrawPending.totalSDM ? totalFlowWithdrawPending.totalSDM : 0,

            totalWAXWithdraw: totalWAXWithdraw && totalWAXWithdraw.totalSDM ? totalWAXWithdraw.totalSDM : 0,
            totalWAXWithdrawPending: totalWAXWithdrawPending && totalWAXWithdrawPending.totalSDM ? totalWAXWithdrawPending.totalSDM : 0,

            flowSdmBalance: (selectedUserSdmInfo.user && selectedUserSdmInfo.user.flowSdmBalance ? selectedUserSdmInfo.user.flowSdmBalance : 0),
            waxSdmBalance: (selectedUserSdmInfo.user && selectedUserSdmInfo.user.waxSdmBalance ? selectedUserSdmInfo.user.waxSdmBalance : 0),

            totalFlowDepositCount: totalFlowDeposit && totalFlowDeposit.count ? totalFlowDeposit.count : 0,
            totalWAXDepositCount: totalWAXDeposit && totalWAXDeposit.count ? totalWAXDeposit.count : 0,

            totalFlowWithdrawCount: totalFlowWithdraw && totalFlowWithdraw.count ? totalFlowWithdraw.count : 0,
            totalFlowWithdrawPendingCount: totalFlowWithdrawPending && totalFlowWithdrawPending.count ? totalFlowWithdrawPending.count : 0,

            totalWAXWithdrawCount: totalWAXWithdraw && totalWAXWithdraw.count ? totalWAXWithdraw.count : 0,
            totalWAXWithdrawPendingCount: totalWAXWithdrawPending && totalWAXWithdrawPending.count ? totalWAXWithdrawPending.count : 0,
        };
    }
    // id: item._id,
    // blockchain: item.blockchain.toUpperCase(),
    // action: item.action.toUpperCase(),
    // status: item.status?item.status.toUpperCase():("SUCCESS"),
    // sdmBalance: item.sdmBalance,

    // createdAt: new Date(item.timestamp).toISOString().substring(0, 10),
    const columns = [
        {
            field: "id",
            headerName: "id",
            minWidth: 150,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">

                        {params.row.id}
                    </div>
                )
            },
        },
        {
            field: "blockchain",
            headerName: "Blockchain",
            minWidth: 100,
            flex: 0.2,
        },
        {
            field: "action",
            headerName: "Action",
            minWidth: 100,
            flex: 0.1,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.status === "SUCCESS" ? (
                                <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800 capitalize">{params.row.status}</span>
                            ) : (params.row.status === "PENDING") ?
                                (
                                    <span className="text-sm bg-yellow-100 p-1 px-2 font-medium rounded-full text-yellow-800 capitalize">{params.row.status}</span>
                                ) ://(params.row.status === "CANCEL" )?
                                (<span className="text-sm bg-red-100 p-1 px-2 font-medium rounded-full text-red-800 capitalize">{params.row.status}</span>)
                            //:(<span className="text-sm bg-pink-100 p-1 px-2 font-medium rounded-full text-pink-800 capitalize">{params.row.status}</span>)
                        }
                    </>
                )
            },
        },
        {
            field: "sdmBalance",
            headerName: "SDM Value",
            minWidth: 200,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.action === "DEPOSIT" ? (
                                <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800 capitalize">{params.row.sdmBalance}</span>
                            ) :
                                (
                                    <span className="text-sm bg-red-100 p-1 px-2 font-medium rounded-full text-red-800 capitalize">{params.row.sdmBalance}</span>
                                )
                        }
                    </>
                )
            },
        },
        {
            field: "createdAt",
            headerName: "Created At",
            type: "date",
            minWidth: 150,
            flex: 0.2,
        },
        // {
        //     field: "actions",
        //     headerName: "Actions",
        //     minWidth: 200,
        //     flex: 0.3,
        //     type: "number",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <Actions editRoute={"user"} deleteHandler={deleteUserHandler} id={params.row.id} name={params.row.name} />
        //         );
        //     },
        // },
    ];

    const rows = [];
    sdmHistory && sdmHistory.forEach(item => {
        // "_id": "629912056045caad3905f2d8",
        // "action": "withdraw",
        // "status": "Pending",
        // "sdmToWithdrawID": "629912056045caad3905f2d6",
        // "sdmBalance": 136.42402143842884,
        // "userID": "62740e02436bf88871c894ac",
        // "timestamp": 1651520389185,
        // "blockchain": "WAX",
        rows.unshift({
            id: item._id,
            blockchain: item.blockchain.toUpperCase(),
            action: item.action.toUpperCase(),
            status: item.status ? item.status.toUpperCase() : ("SUCCESS"),
            sdmBalance: item.sdmBalance,

            createdAt: new Date(item.timestamp).toISOString().substring(0, 19).replace("T", " "),
        });
    });


    return (
        <>
            <MetaData title={"Admin Sdm History by User " + userId + " | Dark Country"} />

            {loading && <BackdropLoader />}

            <h1 className="text-lg font-medium uppercase">Sdm History by User <span className="text-blue-600 p-1 rounded-full bg-blue-100">{userId}</span></h1>


            <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="flex flex-col bg-blue-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Name: {UserSumSDMINFO && UserSumSDMINFO.name ? UserSumSDMINFO.name : ""}</h4>

                </div>
                <div className="flex flex-col bg-blue-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Email: {UserSumSDMINFO && UserSumSDMINFO.email ? UserSumSDMINFO.email : ""}</h4>
                </div>
                <div className="flex flex-col bg-purple-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">SDM FLOW: {UserSumSDMINFO && UserSumSDMINFO.flowSdmBalance ? UserSumSDMINFO.flowSdmBalance : 0}</h4>
                </div>
                <div className="flex flex-col bg-purple-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">SDM WAX: {UserSumSDMINFO && UserSumSDMINFO.waxSdmBalance ? UserSumSDMINFO.waxSdmBalance : 0}</h4>
                </div>
            </div>

            <h1 className="text-lg font-medium uppercase">Sdm History Table</h1>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    //pageSize={100}
                    //autoPageSize={true}
                    rowsPerPageOptions={[50, 100, 250, 500]}
                    disableSelectIconOnClick
                    showCellRightBorder={true}
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}

                />
            </div>


            <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-6">
                <div className="flex flex-col bg-green-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Deposit FLOW</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalFlowDeposit ? UserSumSDMINFO.totalFlowDeposit : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalFlowDepositCount ? UserSumSDMINFO.totalFlowDepositCount : 0}</h4>
                </div>
                <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Withdraw FLOW</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalFlowWithdrawPending ? UserSumSDMINFO.totalFlowWithdraw : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalFlowWithdrawCount ? UserSumSDMINFO.totalFlowWithdrawCount : 0}</h4>
                </div>
                <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Withdraw FLOW PENDING</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalFlowWithdrawPending ? UserSumSDMINFO.totalFlowWithdrawPending : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalFlowWithdrawPendingCount ? UserSumSDMINFO.totalFlowWithdrawPendingCount : 0}</h4>
                </div>

            </div>
            <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-6">
                <div className="flex flex-col bg-green-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Deposit WAX</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalWAXDeposit ? UserSumSDMINFO.totalWAXDeposit : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalWAXDepositCount ? UserSumSDMINFO.totalWAXDepositCount : 0}</h4>
                </div>
                <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Withdraw WAX</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalWAXWithdraw ? UserSumSDMINFO.totalWAXWithdraw : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalWAXWithdrawCount ? UserSumSDMINFO.totalWAXWithdrawCount : 0}</h4>
                </div>
                <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Withdraw WAX PENDING</h4>
                    <h2 className="text-2xl font-bold">{UserSumSDMINFO && UserSumSDMINFO.totalWAXWithdrawPending ? UserSumSDMINFO.totalWAXWithdrawPending : 0}</h2>
                    <h4 className="text-white-100 font-medium">Count: {UserSumSDMINFO && UserSumSDMINFO.totalWAXWithdrawPendingCount ? UserSumSDMINFO.totalWAXWithdrawPendingCount : 0}</h4>
                </div>

            </div>
        </>
    );
};
// UserSumSDMINFO = {
//     id: selectedUserSdmInfo.user._id,
//     name: (selectedUserSdmInfo.user.username ? selectedUserSdmInfo.user.username : (selectedUserSdmInfo.user.waxName ? selectedUserSdmInfo.user.waxName : (selectedUserSdmInfo.user.flowAccount ? selectedUserSdmInfo.user.flowAccount : "none"))),
//     email: (selectedUserSdmInfo.user.email ? (selectedUserSdmInfo.user.email) : "none"),
//     totalFlowDeposit: totalFlowDeposit && totalFlowDeposit.totalSDM ? totalFlowDeposit.totalSDM : 0,
//     totalWAXDeposit: totalWAXDeposit && totalWAXDeposit.totalSDM ? totalWAXDeposit.totalSDM : 0,

//     totalFlowWithdraw: totalFlowWithdraw && totalFlowWithdraw.totalSDM ? totalFlowWithdraw.totalSDM : 0,
//     totalFlowWithdrawPending: totalFlowWithdrawPending && totalFlowWithdrawPending.totalSDM ? totalFlowWithdrawPending.totalSDM : 0,

//     totalWAXWithdraw: totalWAXWithdraw && totalWAXWithdraw.totalSDM ? totalWAXWithdraw.totalSDM : 0,
//     totalWAXWithdrawPending: totalWAXWithdrawPending && totalWAXWithdrawPending.totalSDM ? totalWAXWithdrawPending.totalSDM : 0,

//     flowSdmBalance: (selectedUserSdmInfo.user && selectedUserSdmInfo.user.flowSdmBalance ? selectedUserSdmInfo.user.flowSdmBalance : 0),
//     waxSdmBalance: (selectedUserSdmInfo.user && selectedUserSdmInfo.user.waxSdmBalance ? selectedUserSdmInfo.user.waxSdmBalance : 0)
// };
export default SDMHistoryUserTable;