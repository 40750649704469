import './axios-config';

import { auth } from "./drivers/auth.driver";
import { sdm } from "./drivers/sdm.driver";
import { user } from "./drivers/user.driver";
import { admin } from "./drivers/admin.driver";

export const Api = {
    auth,
    sdm,
    user,
    admin
};
