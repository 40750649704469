import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    CLEAR_ERRORS,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    ALL_USERS_FAIL,
    ALL_USERS_SUCCESS,
    ALL_USERS_REQUEST,
    GET_DASHBOARD,
    GET_USER_SDM_HISTORY_FAIL,
    GET_USER_SDM_HISTORY_SUCCESS,
    GET_USER_SDM_HISTORY_REQUEST,
    GET_USER_SDM_SUM_HISTORY_SUCCESS,
} from '../constants/userConstants';
import { LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_USER_ID,LOCAL_STORAGE_ACCESS_TOKEN } from "../constants/localStorage.constants";
import { to, to2,toLocal } from "../helpers/api.helper";
import { Api } from '../api';

import axios from 'axios';

// Login User
export const loginUser = (email, password) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_USER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }
        
        const { data } = await axios.post(
            to('auth/sign-in/client'),
            { username:email, password:password },
            config
        );
        //console.log(data);
        console.log(data);
        localStorage.setItem(LOCAL_STORAGE_USER_ID, data.user.id);
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, JSON.stringify({value: data.accessToken,expiry:data.expirationDate}));

        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: data//.user,
        });

    } catch (error) {
        console.log(error);
        dispatch({
            type: LOGIN_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const loginUser2 = (email, password) => {
    return axios.post(to('auth/sign-in/client'),
    { username:email, password:password },);
};
// Register User
export const registerUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_USER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }

        const { data } = await axios.post(
            '/api/v1/register',
            userData,
            config
        );

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data.user,
        });

    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Load User
export const loadUser = () => async (dispatch) => {
    try {

        dispatch({ type: LOAD_USER_REQUEST });

        const userID = localStorage.getItem(LOCAL_STORAGE_USER_ID);
        const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
        if(userID && refreshToken){
            const { data } = await Api.auth.refreshToken(userID,refreshToken);
            //console.log(data);
            console.log(data);
            if(data.accessToken){
                localStorage.setItem(LOCAL_STORAGE_USER_ID, data.user.id);
                localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken);
                localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, JSON.stringify({value: data.accessToken,expiry:data.expirationDate}));
                // dispatch({
                //     type: LOAD_USER_SUCCESS,
                //     payload: data.user,
                // });
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: data//.user,
                });
            }
            else{
                dispatch({
                    type: LOGIN_USER_FAIL,
                    payload: data//.user,
                });
                
            }

        }
       

    } catch (error) {
        dispatch({
            type:LOGOUT_USER_SUCCESS,
            payload: error//error.response.data.message,
        });
    }
};

// Logout User
export const logoutUser = () => async (dispatch) => {
    try {
        //await axios.get('/api/v1/logout');
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_USER_ID);
        dispatch({ type: LOGOUT_USER_SUCCESS });
    } catch (error) {
        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update User
export const updateProfile = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PROFILE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }

        const { data } = await axios.put(
            '/api/v1/me/update',
            userData,
            config
        );

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data.success,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update User Password
export const updatePassword = (passwords) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PASSWORD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.put(
            '/api/v1/password/update',
            passwords,
            config
        );

        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: data.success,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response.data.message,
        });
    }
};


// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
    try {

        dispatch({ type: FORGOT_PASSWORD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.post(
            '/api/v1/password/forgot',
            email,
            config
        );

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data.message,
        });

    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
    try {

        dispatch({ type: RESET_PASSWORD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.put(
            `/api/v1/password/reset/${token}`,
            passwords,
            config
        );

        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: data.success,
        });

    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Users ---ADMIN
export const getAllUsers = () => async (dispatch) => {
    try {

        dispatch({ type: ALL_USERS_REQUEST });
        const { data } = await axios.get('/api/v1/admin/users');
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users,
        });

    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getAllSDMWithUsers = () => async (dispatch) => {
    try {

        dispatch({ type: ALL_USERS_REQUEST });
        
        const { data } =  await Api.admin.getSDMUserSActivities();
       
        console.log(data);
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users,
        });

    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error,
        });
    }
};

// Get User Details ---ADMIN
export const getUserDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: USER_DETAILS_REQUEST });
        const { data } = await axios.get(`/api/v1/admin/user/${id}`);

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data.user,
        });

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update User Details ---ADMIN
export const updateUser = (id, userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_USER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }

        const { data } = await axios.put(
            `/api/v1/admin/user/${id}`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: data.success,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Delete User ---ADMIN
export const deleteUser = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_USER_REQUEST });
        const { data } = await axios.delete(`/api/v1/admin/user/${id}`);

        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data.success,
        });

    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};
export const getDashBoard = () => async (dispatch) => {
    try {

        //dispatch({ type: ALL_USERS_REQUEST });
        const { data } =  await Api.admin.getDashboard();
        dispatch({
            type: GET_DASHBOARD,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message,
        });
    }
};
//Get user sdmHistory
export const getSDMUUserHistory = (userId) =>async (dispatch)=>{
    try {
        
        dispatch({ type: GET_USER_SDM_HISTORY_REQUEST });
        const { data } = await Api.admin.getSDMHistoryByUser(userId);
        console.log("dsdsdsdsdsdsds");
        console.log(data);
        if(data.users && data.users[0]){
            dispatch({ type: GET_USER_SDM_SUM_HISTORY_SUCCESS,
                payload: data.users[0],
            });
        }
        dispatch({ type: GET_USER_SDM_HISTORY_SUCCESS,
            payload: data.sdmHistory,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_SDM_HISTORY_FAIL,
            payload: error.response.data.message,
        });
    }

};
// Clear All Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};