import WebFont from 'webfontloader';
import Header from './components/Layouts/Header/Header';
import Login from './components/User/Login';

import { Routes, Route, useLocation } from 'react-router-dom';
import { loadUser } from './actions/userAction';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ProtectedRoute from './Routes/ProtectedRoute';
import Dashboard from './components/Admin/Dashboard';
import MainData from './components/Admin/MainData';
import OrderTable from './components/Admin/OrderTable';




import UserTable from './components/Admin/UserTable';
import SDMHistory from './components/Admin/SDMHistoryTable';
import UpdateUser from './components/Admin/UpdateUser';
import SDMHistoryUserTable from './components/Admin/SDMHistoryUserTable';

import NotFound from './components/NotFound';

import { LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_USER_ID } from "./constants/localStorage.constants";
// import { getFromLocalStorageWithExpiry } from "./helpers/storage.helper";

function App() {

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const [stripeApiKey, setStripeApiKey] = useState("");

  // async function getStripeApiKey() {
  //   const { data } = await axios.get('/api/v1/stripeapikey');
  //   setStripeApiKey(data.stripeApiKey);
  // }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:300,400,500,600,700"]
      },
    });
  });

  useEffect(() => {
   
    const userID = localStorage.getItem(LOCAL_STORAGE_USER_ID);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
    console.log(userID);
    console.log(refreshToken);
    if (userID && refreshToken) {
      dispatch(loadUser());
  }
   
    // getStripeApiKey();
  }, [dispatch]);
  useEffect(() => {

  }, [dispatch]);
  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname])


  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        
        <Route path="/admin/dashboard" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={0}>
              <MainData />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        

        <Route path="/admin/sdm" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={1}>
              <SDMHistory />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>
        <Route path="/admin/sdm/user/:userid" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <SDMHistoryUserTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>
        
        <Route path="/admin/users" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <UserTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>


        <Route path="/admin/user/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <UpdateUser />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>
        
        
        <Route path="*" element={<NotFound />}></Route>

      </Routes>
    
    </>
  );
}

export default App;
