import { useEffect } from 'react';
import Chart from 'chart.js/auto'
import { Doughnut, Line, Pie, Bar } from 'react-chartjs-2';

import { useSelector, useDispatch } from 'react-redux';
import { getAllOrders } from '../../actions/orderAction';
import { getAllUsers, getDashBoard } from '../../actions/userAction';
import { categories } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';

const MainData = () => {

    const dispatch = useDispatch();

    const { products } = useSelector((state) => state.products);
    const { orders } = useSelector((state) => state.allOrders);
    const { users } = useSelector((state) => state.users);

    const { dashboard } = useSelector((state) => state.users);

    let outOfStock = 0;

    products?.forEach((item) => {
        if (item.stock === 0) {
            outOfStock += 1;
        }
    });

    useEffect(() => {
       
       // dispatch(getAllOrders());
        //dispatch(getAllSDMWithUsers());
        dispatch(getDashBoard())
    }, [dispatch]);
    console.log(dashboard);
    let totalFlow = dashboard && dashboard.dashboard ? dashboard.dashboard.find(x=>x && x.blockchain ==="FLOW"): null;
    let totalWAX = dashboard && dashboard.dashboard ? dashboard.dashboard.find(x=>x && x.blockchain ==="WAX"): null;

    let totalFlowDeposit = totalFlow && totalFlow.actions ? (totalFlow.actions.find(x=>x&&x.action==='deposit')):null;
    let totalWAXDeposit = totalWAX && totalWAX.actions ? (totalWAX.actions.find(x=>x&&x.action==='deposit')):null;

    let totalFlowWithdraw = totalFlow && totalFlow.actions ? (totalFlow.actions.find(x=>x&&x.action==='withdraw' && x.status==="Success")):null;
    let totalWAXWithdraw = totalWAX && totalWAX.actions ? (totalWAX.actions.find(x=>x&&x.action==='withdraw' && x.status==="Success")):null;

    let totalFlowWithdrawPending = totalFlow && totalFlow.actions ? (totalFlow.actions.find(x=>x&&x.action==='withdraw' && x.status==="Pending")):null;
    let totalWAXWithdrawPending = totalWAX && totalWAX.actions ? (totalWAX.actions.find(x=>x&&x.action==='withdraw' && x.status==="Pending")):null;

    let totalAmount = orders?.reduce((total, order) => total + order.totalPrice, 0);

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const date = new Date();
    const lineState = {
        labels: months,
        datasets: [
            {
                label: `Sales in ${date.getFullYear() - 2}`,
                borderColor: '#8A39E1',
                backgroundColor: '#8A39E1',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear() - 2).reduce((total, od) => total + od.totalPrice, 0)),
            },
            {
                label: `Sales in ${date.getFullYear() - 1}`,
                borderColor: 'orange',
                backgroundColor: 'orange',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear() - 1).reduce((total, od) => total + od.totalPrice, 0)),
            },
            {
                label: `Sales in ${date.getFullYear()}`,
                borderColor: '#4ade80',
                backgroundColor: '#4ade80',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear()).reduce((total, od) => total + od.totalPrice, 0)),
            },
        ],
    };

    const statuses = ['Processing', 'Shipped', 'Delivered'];

    const pieState = {
        labels: statuses,
        datasets: [
            {
                backgroundColor: ['#9333ea', '#facc15', '#4ade80'],
                hoverBackgroundColor: ['#a855f7', '#fde047', '#86efac'],
                data: statuses.map((status) => orders?.filter((item) => item.orderStatus === status).length),
            },
        ],
    };

    const doughnutState = {
        labels: ['Out of Stock', 'In Stock'],
        datasets: [
            {
                backgroundColor: ['#ef4444', '#22c55e'],
                hoverBackgroundColor: ['#dc2626', '#16a34a'],
               // data: [outOfStock, products.length - outOfStock],
            },
        ],
    };

    const barState = {
        labels: categories,
        datasets: [
            {
                label: "Products",
                borderColor: '#9333ea',
                backgroundColor: '#9333ea',
                hoverBackgroundColor: '#6b21a8',
                data: categories.map((cat) => products?.filter((item) => item.category === cat).length),
            },
        ],
    };

    return (
        <>
            <MetaData title="Admin Dashboard | Dark Country" />

            <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-6">
                <div className="flex flex-col bg-green-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX Deposited</h4>
                    <h2 className="text-2xl font-bold">{totalWAXDeposit&& totalWAXDeposit.totalSDM?totalWAXDeposit.totalSDM.toLocaleString() :0}</h2>
                </div>
                <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX Withdrawed</h4>
                    <h2 className="text-2xl font-bold">{totalWAXWithdraw&& totalWAXWithdraw.totalSDM?totalWAXWithdraw.totalSDM.toLocaleString() :0}</h2>
                </div>
                <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX Withdrawed PENDING</h4>
                    <h2 className="text-2xl font-bold">{totalWAXWithdrawPending&& totalWAXWithdrawPending.totalSDM?totalWAXWithdrawPending.totalSDM.toLocaleString() :0}</h2>
                </div>
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-6">
                <div className="flex flex-col bg-green-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total FLOW Deposited</h4>
                    <h2 className="text-2xl font-bold">{totalFlowDeposit&& totalFlowDeposit.totalSDM?totalFlowDeposit.totalSDM.toLocaleString() :0}</h2>
                </div>
                <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                <h4 className="text-gray-100 font-medium">Total FLOW Withdrawed</h4>
                    <h2 className="text-2xl font-bold">{totalFlowWithdraw&& totalFlowWithdraw.totalSDM?totalFlowWithdraw.totalSDM.toLocaleString() :0}</h2>
                </div>
                <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                <h4 className="text-gray-100 font-medium">Total FLOW Withdrawed PENDING</h4>
                    <h2 className="text-2xl font-bold">{totalFlowWithdrawPending&& totalFlowWithdrawPending.totalSDM?totalFlowWithdrawPending.totalSDM.toLocaleString() :0}</h2>
                </div>
                
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-6">
                
                <div className="flex flex-col bg-indigo-800 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX/FLOW Deposit Transactions</h4>
                    <h2 className="text-2xl font-bold">{(totalWAXDeposit&& totalWAXDeposit.count?totalWAXDeposit.count.toLocaleString() :0)+
                    "/"+(totalFlowDeposit&& totalFlowDeposit.count?totalFlowDeposit.count.toLocaleString() :0)}</h2>
                </div>
                <div className="flex flex-col bg-pink-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX/FLOW Withdraw Transactions</h4>
                    <h2 className="text-2xl font-bold">{(totalWAXWithdraw&& totalWAXWithdraw.count?totalWAXWithdraw.count.toLocaleString() :0)+
                    "/"+(totalFlowWithdraw&& totalFlowWithdraw.count?totalFlowWithdraw.count.toLocaleString() :0)}</h2>
                </div>
                <div className="flex flex-col bg-purple-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total WAX/FLOW Withdraw Transactions Pending</h4>
                    <h2 className="text-2xl font-bold">{(totalWAXWithdrawPending&& totalWAXWithdrawPending.count?totalWAXWithdrawPending.count.toLocaleString() :0)+
                    "/"+(totalFlowWithdrawPending&& totalFlowWithdrawPending.count?totalFlowWithdrawPending.count.toLocaleString() :0)}</h2>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-1 gap-2 sm:gap-6">
            
                <div className="flex flex-col bg-blue-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Users</h4>
                    <h2 className="text-2xl font-bold">{(users && users.length? users.length: 0)+"/"+(dashboard && dashboard.countofUser ? dashboard.countofUser:0)}</h2>
                </div>
                
            </div>
            
            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Line data={lineState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Order Status</span>
                    <Pie data={pieState} />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full mb-6">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Bar data={barState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Stock Status</span>
                    <Doughnut data={doughnutState} />
                </div>
            </div>
        </>
    );
};

export default MainData;
