import axios from "axios";

import { to, to2,toLocal } from "../../helpers/api.helper";

export const user = {
    getProfile: ({ userID }) => {
        return axios.get(to(`user/profile?id=${userID}`));
    },

    updateUsername: ({ username }) => {
        return axios.patch(to('user/username'), { username });
    },

    setPassword: ({ password }) => {
        return axios.post(to('user/password/set'), { password });
    },

    changePassword: ({ oldPassword, newPassword }) => {
        return axios.patch(to('user/password/change'), { oldPassword, newPassword });
    },

    resetPassword: ({ id, token, password }) => {
        return axios.post(to('user/password/reset'), { id, token, password });
    },



   
    getUserSubArqStatus: () => {
        return axios.get(to2("user/subscription/arq"));
    },
};
