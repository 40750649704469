import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, gridFilterActiveItemsLookupSelector } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { clearErrors, deleteUser, getAllSDMWithUsers } from '../../actions/userAction';
import { DELETE_USER_RESET } from '../../constants/userConstants';
import Actions from './Actions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const SDMHistory = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { users, error } = useSelector((state) => state.users);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.profile);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        // if (deleteError) {
        //     enqueueSnackbar(deleteError, { variant: "error" });
        //     dispatch(clearErrors());
        // }
        // if (isDeleted) {
        //     enqueueSnackbar("User Deleted Successfully", { variant: "success" });
        //     dispatch({ type: DELETE_USER_RESET });
        // }

        dispatch(getAllSDMWithUsers());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteUserHandler = (id) => {
        dispatch(deleteUser(id));
    }

    const columns = [

        {
            field: "id",
            headerName: "Id",
            minWidth: 220,
            flex: 0.2,
            renderCell: (params) => {
                        return (
                            <Link to={`/admin/sdm/user/${params.row.id}`} className="text-blue-600 hover:bg-blue-200 p-1 rounded-full bg-blue-100">
                            {params.row.id}
                        </Link>
                        );
                    
            }
        },
        {
            field: "name",
            headerName: "UserName/WaxName/FlowName",
            minWidth: 200,
            flex: 0.2,
        },

        {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            flex: 0.2,
        },
        // totalFlowDeposit
        // totalWAXDeposit
        // totalFlowWithdraw
        // totalWAXWithdraw
        //flowSdmBalance
        //waxSdmBalance
        {
            field: "totalFlowDeposit",
            headerName: "FLOW Deposit",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800 capitalize">{params.row.totalFlowDeposit}</span>


                    </>
                )
            },
        },
        
        {
            field: "totalFlowWithdraw",
            headerName: "FLOW Withdraw",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-red-100 p-1 px-2 font-medium rounded-full text-red-800 capitalize">{params.row.totalFlowWithdraw}</span>
                    </>
                )
            },
        },
        {
            field: "totalFlowWithdrawPending",
            headerName: "FLOW Withdraw Pending",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-yellow-100 p-1 px-2 font-medium rounded-full text-yellow-800 capitalize">{params.row.totalFlowWithdrawPending}</span>
                    </>
                )
            },
        },
        {
            field: "totalWAXDeposit",
            headerName: "WAX Deposit",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800 capitalize">{params.row.totalWAXDeposit}</span>


                    </>
                )
            },
        },
        {
            field: "totalWAXWithdraw",
            headerName: "WAX Wathdraw",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-red-100 p-1 px-2 font-medium rounded-full text-red-800 capitalize">{params.row.totalWAXWithdraw}</span>
                    </>
                )
            },
        },
        {
            field: "totalWAXWithdrawPending",
            headerName: "WAX Wathdraw Pending",
            minWidth: 200,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        <span className="text-sm bg-yellow-100 p-1 px-2 font-medium rounded-full text-yellow-800 capitalize">{params.row.totalWAXWithdrawPending}</span>
                    </>
                )
            },
        },

        {
            field: "waxSdmBalance",
            headerName: "Balance WAX",
            minWidth: 200,
            flex: 0.1,
        },
        {
            field: "flowSdmBalance",
            headerName: "Balance Flow",
            minWidth: 200,
            flex: 0.1,
        },


        // {
        //     field: "actions",
        //     headerName: "Actions",
        //     minWidth: 200,
        //     flex: 0.3,
        //     type: "number",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <Actions editRoute={"sdm/user"} deleteHandler={deleteUserHandler} id={params.row.id} name={params.row.name} />
        //         );
        //     },
        // },
    ];

    const rows = [];

    users && users.forEach((item) => {
        var totalFlow = item.sdm.find(el => el && el.blockchain === "FLOW");

        var totalFlowDeposit = totalFlow ? totalFlow.values.find(el => el && el.action === "deposit") : null;
        var totalFlowWithdraw = totalFlow ? totalFlow.values.find(el => el && el.action === "withdraw" && el.status === "Success") : null;
        var totalFlowWithdrawPending = totalFlow ? totalFlow.values.find(el => el && el.action === "withdraw" && el.status === "Pending") : null;

        var totalWAX = item.sdm.find(el => el && el.blockchain === "WAX");

        var totalWAXDeposit = totalWAX ? totalWAX.values.find(el => el && el.action === "deposit") : null;
        var totalWAXWithdraw = totalWAX ? totalWAX.values.find(el => el && el.action === "withdraw" && el.status === "Success") : null;
        var totalWAXWithdrawPending = totalWAX ? totalWAX.values.find(el => el && el.action === "withdraw" && el.status === "Pending") : null;

        rows.unshift({
            id: item.user._id,
            name: (item.user.username ? item.user.username : (item.user.waxName ? item.user.waxName : (item.user.flowAccount ? item.user.flowAccount : "none"))),
            email: (item.user.email ? (item.user.email) : "none"),
            totalFlowDeposit: totalFlowDeposit && totalFlowDeposit.totalSDM ? totalFlowDeposit.totalSDM : 0,
            totalWAXDeposit: totalWAXDeposit && totalWAXDeposit.totalSDM ? totalWAXDeposit.totalSDM : 0,
            
            totalFlowWithdraw: totalFlowWithdraw && totalFlowWithdraw.totalSDM ? totalFlowWithdraw.totalSDM : 0,
            totalFlowWithdrawPending: totalFlowWithdrawPending && totalFlowWithdrawPending.totalSDM ? totalFlowWithdrawPending.totalSDM : 0,
           
            totalWAXWithdraw: totalWAXWithdraw && totalWAXWithdraw.totalSDM ? totalWAXWithdraw.totalSDM : 0,
            totalWAXWithdrawPending: totalWAXWithdrawPending && totalWAXWithdrawPending.totalSDM ? totalWAXWithdrawPending.totalSDM : 0,
            
            flowSdmBalance: (item.user && item.user.flowSdmBalance ? item.user.flowSdmBalance : 0),
            waxSdmBalance: (item.user && item.user.waxSdmBalance ? item.user.waxSdmBalance : 0)
        });
    });

    return (
        <>
            <MetaData title="Admin Users SDM | Dark Country" />

            {loading && <BackdropLoader />}

            <h1 className="text-lg font-medium uppercase">SDM</h1>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
            </div>
        </>
    );
};

export default SDMHistory;