
import axios from "axios";

import { to, to2,toLocal } from "../../helpers/api.helper";

export const admin = {
   
    getSDMUserSActivities: () => {
        
        return axios.get(to("admin/sdm/history"));
    },
    getDashboard: () => {
        
        return axios.get(to("admin/dashboard"));
    },

    getSDMHistoryByUser: (userId) => {
        
        return axios.get(to("admin/sdm/history/user/"+userId));
    },
};
